import { fetchStaticCmsByType } from 'api/cms';
import { captureError } from 'utils/raven';
import { Robots } from '../types';

export const getSearchVolumeKeywords = async (locale?: string) => {
  const data = await fetchStaticCmsByType({
    key: 'search-volume-keywords',
    locale,
  });

  if (!data || !data.data?.Text) {
    return null;
  }

  try {
    const searchVolumeKeywords = JSON.parse(data.data.Text);

    return searchVolumeKeywords;
  } catch (error) {
    captureError('Failed to fetch search volume keywords', {
      error,
      tag: 'get-request',
    });

    return null;
  }
};

export const shouldShowCategoryLinksBySearchVolume = (robots: Robots) => {
  return robots === Robots.INDEX_FOLLOW;
};
