import { ZDTCatalog } from '@zalora/doraemon-ts';
import { getCurrencyCode } from 'utils/money';
import * as productUtils from 'utils/product';
import { getAbsoluteProductUrlFromSlug } from 'utils/url';

const NUMBER_OF_OFFERED_PRODUCTS = 10;

export const getAggregateOffer = (products: ZDTCatalog.Product[]) => {
  if (products.length === 0) {
    return { lowPrice: 0, highestPrice: 0 };
  }

  const prices = products.map((product) => productUtils.getFinalPrice(product));

  const lowPrice = Math.min(...prices);
  const highestPrice = Math.max(...prices);

  return { lowPrice, highestPrice };
};

export const getProductJsonLd = (product: ZDTCatalog.Product, locale?: string) => {
  const productName = productUtils.getName(product);
  const price = productUtils.getFinalPrice(product);
  const productUrl = productUtils.getURL(product);
  const productImage = productUtils.getMainImageURL(product);

  return {
    '@type': 'Product',
    image: productImage,
    url: getAbsoluteProductUrlFromSlug(productUrl, locale),
    name: productName,
    offers: {
      '@type': 'Offer',
      availability: 'http://schema.org/InStock',
      priceCurrency: getCurrencyCode(),
      price: price ? price.toString() : '',
    },
  };
};

export const getProductListJsonLd = ({
  products,
  metaTitle,
  metaDescription,
  pageURL,
  mainImageThumbnail,
  numberOfItems,
  locale,
}: {
  products: ZDTCatalog.Product[];
  metaTitle: string;
  metaDescription: string;
  pageURL: string;
  mainImageThumbnail: string;
  numberOfItems: number;
  locale?: string;
}) => {
  const { lowPrice, highestPrice } = getAggregateOffer(products);

  const data = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: metaTitle,
    description: metaDescription,
    url: pageURL,
    image: mainImageThumbnail,
    offers: {
      '@type': 'AggregateOffer',
      priceCurrency: getCurrencyCode(),
      highPrice: highestPrice.toString(),
      lowPrice: lowPrice.toString(),
    },
    mainEntity: {
      '@type': 'ItemList',
      numberOfItems: numberOfItems.toString(),
      itemListElement: products
        .slice(0, NUMBER_OF_OFFERED_PRODUCTS)
        .map((product) => getProductJsonLd(product, locale)),
    },
  };

  return data;
};
