import { ZDTAds } from '@zalora/doraemon-ts';
import clsx from 'clsx';
import { useStaticCatalogContext } from 'context/CatalogContext';
import React, { FC } from 'react';
import { transformCMSBanner } from 'utils/catalog/catalog-ads';
import CampaignBanner from './CampaignBanner';
import { SponsoredBanner } from './SponsoredBanner';

export const BANNER_ID = 'z-catalog-banner';

interface Props {
  desktopSponsoredBannerSize: ZDTAds.BannerSize | null;
  mobileSponsoredBannerSize: ZDTAds.BannerSize | null;
}

export const CatalogBanner: FC<Props> = ({
  desktopSponsoredBannerSize,
  mobileSponsoredBannerSize,
}) => {
  const { catalogAds = null, cmsBanner } = useStaticCatalogContext();
  const derivedCMSBanner = transformCMSBanner(cmsBanner);

  const {
    AdID = '',
    ImageURL = '',
    BannerFullWidth = '',
    isFullScreenBanner = false,
  } = derivedCMSBanner || {};

  const hasSponsoredBanners = catalogAds
    ? catalogAds.CatalogBanners?.some(Boolean)
    : !!desktopSponsoredBannerSize || !!mobileSponsoredBannerSize;
  const hasCampaignBanner = !!BannerFullWidth || (!!ImageURL && !AdID);

  if (!hasCampaignBanner && !hasSponsoredBanners) {
    return null;
  }

  return (
    <div
      className={clsx({
        'mx-auto w-full max-w-screen-xl': !isFullScreenBanner,
        'desktop:w-full desktop:p-4': BannerFullWidth,
      })}
      id={BANNER_ID}
    >
      {hasSponsoredBanners ? (
        <SponsoredBanner
          mobileSponsoredBannerSize={mobileSponsoredBannerSize}
          desktopSponsoredBannerSize={desktopSponsoredBannerSize}
        />
      ) : (
        <CampaignBanner
          catalogBanner={cmsBanner || null}
          isFullScreenBanner={isFullScreenBanner}
        />
      )}
    </div>
  );
};
