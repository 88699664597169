import { ZDTSEO } from '@zalora/doraemon-ts';
import { useStaticCatalogContext } from 'context/CatalogContext';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { QueryParams } from 'api/APIClient';
import { HrefLang, OpenGraphTags, PageInfo } from 'components/SEO';
import { CitrusTracking } from 'components/ZAP';
import { APP_LOGO_IMAGE_URL } from 'constants/images';
import { getCanonical, getRobots } from 'utils/catalog/catalog-seo';
import { getStaticAsset } from 'utils/domains';
import { getAbsoluteUrl } from 'utils/url';
import { BreadcrumbListStructuredData } from './BreadcrumbListStructuredData';
import { CatalogOrganizationStructuredData } from './CatalogOrganizationStructuredData';
import { CatalogPaginationLinks } from './CatalogPaginationLinks';
import { CatalogUniversalLinks } from './CatalogUniversalLinks';
import { ProductListStructuredData } from './ProductListStructuredData';

const getPageTitle = ({
  searchTerm,
  seoInfo,
  defaultTitle,
}: {
  searchTerm?: string;
  seoInfo: ZDTSEO.CatalogInfo;
  defaultTitle: string;
}) => {
  // if search page, page title is search term
  if (searchTerm) {
    return searchTerm;
  }

  // if not available, get page title from SEO info (SEO configuration)
  if (seoInfo?.PageTitle) {
    return seoInfo.PageTitle;
  }

  // otherwise, return defaul page title
  return defaultTitle;
};

const getPageDescription = ({ seoInfo }: { seoInfo: ZDTSEO.CatalogInfo }) => {
  return seoInfo.PageDescription || '';
};

const CatalogHead = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { pageInfo, seoInfo, params } = useStaticCatalogContext();
  const title = getPageTitle({
    searchTerm: params.q as string,
    seoInfo,
    defaultTitle: t('ZALORA Online Fashion Shopping'),
  });
  const description = getPageDescription({ seoInfo });
  const hrefLangTag = seoInfo.HreflangTag;
  const url = router.asPath;
  const canonical = getCanonical(router.asPath, pageInfo, router.query as QueryParams);
  const robots = getRobots(router.query as QueryParams, pageInfo);

  return (
    <>
      <PageInfo
        title={title}
        description={description}
        canonical={canonical}
        robots={robots}
      />
      <OpenGraphTags
        title={title}
        description={description}
        image={getStaticAsset(APP_LOGO_IMAGE_URL, false)}
        imageWidth="600"
        imageHeight="600"
        url={url}
      />
      <CatalogUniversalLinks />
      <HrefLang hrefLangTag={hrefLangTag} />
      <CatalogPaginationLinks />
      <CitrusTracking />

      {/* Structured Data */}
      <BreadcrumbListStructuredData />
      <ProductListStructuredData
        metaTitle={title}
        metaDescription={description}
        mainImageThumbnail={getStaticAsset(APP_LOGO_IMAGE_URL, false)}
        pageURL={getAbsoluteUrl(url, router.locale as string)}
      />
      <CatalogOrganizationStructuredData
        name={title}
        url={canonical}
      />
    </>
  );
};

export default memo(CatalogHead);
