import dynamic from 'next/dynamic';
import { FC, memo } from 'react';
import CategoriesInternalLinks from 'components/CategoriesInternalLinks/CategoriesInternalLinks';
import { shouldShowCategoryLinksBySearchVolume } from 'utils/catalog/category-search-volume/search-volume-keywords';
import { Robots } from 'utils/catalog/types';
import DynamicTable from './DynamicTable';
import RelatedBrands from './RelatedBrands';
import SEOText from './SEOText';

const RelatedCategories = dynamic(() => import('./RelatedCategories'), {
  ssr: true,
});

interface Props {
  seoText?: string | null;
  robots?: Robots;
}

const CatalogInfo: FC<Props> = ({ seoText, robots }) => {
  const shouldShowCateLinksBySearchVolume = robots
    ? shouldShowCategoryLinksBySearchVolume(robots)
    : false;

  return (
    <div className="mt-10 flex flex-col gap-5 px-5 text-xs desktop:flex-row">
      <div className="flex flex-1 flex-col empty:hidden">
        <SEOText data={seoText} />
        <RelatedBrands />
        {shouldShowCateLinksBySearchVolume ? <RelatedCategories /> : null}
        <CategoriesInternalLinks
          className="catalog-info"
          type="column"
        />
      </div>
      <div className="w-full empty:hidden desktop:w-2/5">
        <DynamicTable />
      </div>
    </div>
  );
};

export default memo(CatalogInfo);
