import { useDynamicCatalogContext, useStaticCatalogContext } from 'context/CatalogContext';
import { useRouter } from 'next/router';
import React from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  SOURCE_PAGE_TYPE,
  trackingOnClickBreadcrumb,
} from 'components/Breadcrumbs/Breadcrumbs.tracking';
import useNewsLetter from 'hooks/api/useNewsLetter';
import useUser from 'hooks/api/useUser';
import { useUiStore } from 'stores/ui';
import * as uiSelectors from 'stores/ui/selectors';
import { isUserSubscribedNewsLetter } from 'utils/user/news-letter/isUserSubscribedNewsLetter';

export const CatalogBreadcrumbs = () => {
  const { locale } = useRouter();
  const {
    data: { products },
  } = useDynamicCatalogContext();
  const { breadcrumbs, seoInfo } = useStaticCatalogContext();
  const userData = useUser();
  const activeSegment = useUiStore(uiSelectors.activeSegment);
  const { data: newsLetter } = useNewsLetter();

  if (!products || products.NumProductFound === 0) {
    return null;
  }

  return (
    <Breadcrumbs
      onBreadcrumbItemClick={(label, deeplink) => {
        trackingOnClickBreadcrumb({
          title: label,
          sourcePageType: SOURCE_PAGE_TYPE.CATALOG,
          sourceSegment: seoInfo?.PageInfo?.Segment?.Key || '',
          deeplink,
          userData,
          language: locale,
          activeSegment,
          emailSubscribed: isUserSubscribedNewsLetter(newsLetter),
        });
      }}
      items={breadcrumbs}
      className="mb-5 hidden desktop:flex"
    />
  );
};
