import React, { FC, memo } from 'react';
import SCREEN_SIZES from 'constants/screen-size';

interface Props {
  imageURL: string;
  alt?: string;
}

const MobileCampaignBanner: FC<Props> = ({ imageURL, alt }) => {
  return (
    <div className="m-3 desktop:hidden">
      <picture>
        <source
          media={`(max-width: ${SCREEN_SIZES.DESKTOP.minPixels}px)`}
          srcSet={imageURL}
        />
        <img
          alt={alt}
          className="w-full rounded-lg object-contain"
        />
      </picture>
    </div>
  );
};

export default memo(MobileCampaignBanner);
