import { useDynamicCatalogContext } from 'context/CatalogContext';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { PER_PAGE } from 'constants/catalog';
import { getDomainByLocale } from 'utils/domains';
import { setQueryParams } from 'utils/url';

export const CatalogPaginationLinks = () => {
  const router = useRouter();
  const {
    data: { products },
  } = useDynamicCatalogContext();

  if (!products) {
    return null;
  }

  const domain = getDomainByLocale(router.locale as string);
  const currentPage = Number(router.query.page || 1);
  const totalPages = products.NumProductFound ? Math.ceil(products.NumProductFound / PER_PAGE) : 0;

  return (
    <Head>
      {currentPage > 1 ? (
        <link
          rel="prev"
          href={`${domain}${setQueryParams(router.asPath, {
            page: (currentPage - 1).toString(),
          })}`}
        />
      ) : null}

      {currentPage + 1 < totalPages ? (
        <link
          rel="next"
          href={`${domain}${setQueryParams(router.asPath, {
            page: (currentPage + 1).toString(),
          })}`}
        />
      ) : null}
    </Head>
  );
};
