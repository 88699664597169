import { FC, memo } from 'react';
import { Price } from 'components/Price';
import { LotusProduct } from 'types/LDT';
import { ZDTPromotion } from 'types/ZDT';
import * as productUtils from 'utils/product';

interface Props {
  product: LotusProduct;
  promotions?: ZDTPromotion.PromotionMap['Promotions'];
  isLoading?: boolean;
}

const ProductItemPrice: FC<Props> = ({ product, promotions, isLoading }) => {
  const productConfigSku = productUtils.getConfigSku(product);
  const price = productUtils.getPrice(product);
  const specialPrice = productUtils.getSpecialPrice(product);

  const promo = promotions?.[productConfigSku]?.[0] ?? {};
  const { EffectivePriceFormatted = '', EffectivePrice = 0 } = promo;

  const hasVoucher = !!EffectivePriceFormatted;

  const discountedPrice = {
    price: hasVoucher ? EffectivePrice : specialPrice,
    hasPriceRange: 'HasDifferentSimplePrices' in product && product.HasDifferentSimplePrices,
    hasVoucher,
  };

  return (
    <Price
      isLoading={isLoading}
      originalPrice={price}
      discountedPrice={discountedPrice}
    />
  );
};

export default memo(ProductItemPrice);
