/* eslint-disable @next/next/no-img-element */
import { ZDTAds } from '@zalora/doraemon-ts';
import { useStaticCatalogContext } from 'context/CatalogContext';
import React, { FC } from 'react';
import { getStaticAsset } from 'utils/domains';
import { SponsoredBannerComponent } from './SponsoredBannerContent';

interface Props {
  desktopSponsoredBannerSize: ZDTAds.BannerSize | null;
  mobileSponsoredBannerSize: ZDTAds.BannerSize | null;
}

export const SponsoredBanner: FC<Props> = ({
  desktopSponsoredBannerSize,
  mobileSponsoredBannerSize,
}) => {
  const { catalogAds } = useStaticCatalogContext();
  const { RatioHeight: desktopHeight, RatioWidth: desktopWidth } = desktopSponsoredBannerSize || {};
  const { RatioHeight: mobileHeight, RatioWidth: mobileWidth } = mobileSponsoredBannerSize || {};
  const desktopBannerRatio = desktopHeight && desktopWidth ? desktopWidth / desktopHeight : 0;
  const mobileBannerRatio = mobileHeight && mobileWidth ? mobileWidth / mobileHeight : 0;

  if (!catalogAds) {
    return (
      <>
        {/* desktop sponsored banner skeleton */}
        {desktopBannerRatio ? (
          <div className="desktop:py-4">
            <img
              alt="sponsored banner skeleton"
              src={getStaticAsset('/static-assets/images/catalog/banner_skeleton.webp')}
              className="hidden w-full animate-pulse rounded-lg bg-grey-20 object-cover object-center desktop:block"
              style={{ aspectRatio: desktopBannerRatio ? desktopBannerRatio : 'auto' }}
            />
          </div>
        ) : null}
        {/* mobile sponsored banner skeleton */}
        {mobileBannerRatio ? (
          <div className="p-4 desktop:p-0">
            <img
              alt="sponsored banner skeleton"
              src={getStaticAsset('/static-assets/images/catalog/banner_skeleton.webp')}
              className="animate-pulse rounded-lg bg-grey-20 object-cover object-center desktop:hidden"
              style={{ aspectRatio: mobileBannerRatio ? mobileBannerRatio : 'auto' }}
            />
          </div>
        ) : null}
      </>
    );
  }

  /**
   * `CatalogBanners` data filtered to have only mobile and desktop banners
   * at getServerSideProps of `catalog`
   */
  const mobileBanner = catalogAds?.CatalogBanners?.[0];
  const desktopBanner = catalogAds?.CatalogBanners?.[1];

  return (
    <>
      {/* Render both banners with CSS to show/hide so that appropriate data,
        e.g. banner link, of each screen is displayed */}
      <SponsoredBannerComponent
        banner={mobileBanner}
        ratio={mobileBannerRatio}
        isMobile
        className="block p-4 desktop:hidden"
      />
      <SponsoredBannerComponent
        ratio={desktopBannerRatio}
        banner={desktopBanner}
        className="hidden py-4 desktop:block"
      />
    </>
  );
};
