import React, { FC } from 'react';
import { getStaticAsset } from 'utils/domains';

interface Props {
  data?: string | null;
}

const SEOText: FC<Props> = ({ data }) => {
  const styles = getStaticAsset('/static-assets/legacy/css/catalog-info.css');

  if (!data) {
    return null;
  }

  return (
    <div className="catalog-info">
      <link
        rel="stylesheet"
        id="catalog-info"
        href={styles}
      />

      <div
        className="mtl box fct-text fsm mbm"
        dangerouslySetInnerHTML={{ __html: data }}
      ></div>
    </div>
  );
};

export default SEOText;
