import OrganizationData from '../components/OrganizationStructuredData/data.json';
import { isObject } from './validation';

type OrganizationDataByLocale = typeof OrganizationData;
type OrganizationLocale = keyof OrganizationDataByLocale;
type OrganizationData = (typeof OrganizationData)['en-SG'];
export type OrganizationDataExtended = Partial<OrganizationData> & {
  aggregateRating?: {
    ratingValue: number;
    reviewCount: number;
  };
};

export const isOrganizationLocale = (
  obj: OrganizationDataByLocale,
  key: string,
): key is OrganizationLocale => {
  return isObject(obj) && key in obj;
};

export const getOrganizationStructuredData = (
  locale: string | undefined,
  shouldIncludeSameAs = true,
  pageData?: OrganizationDataExtended,
) => {
  if (!locale || !isOrganizationLocale(OrganizationData, locale)) {
    return undefined;
  }

  const orgData = OrganizationData[locale];
  const data = { ...orgData, ...(pageData || {}) };

  const {
    name,
    legalName,
    description,
    customerServiceEmail,
    facebookHandle,
    instagramHandle,
    youtubeHandle,
    url,
    aggregateRating,
  } = data;

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'OnlineStore',
    legalName,
    name,
    description,
    url,
    logo: {
      '@type': 'ImageObject',
      url: `${orgData.url}favicon.ico`,
    },
    contactPoint: {
      '@type': 'ContactPoint',
      email: customerServiceEmail,
      contactType: 'Customer Service',
    },
  } as Record<string, unknown>;

  if (shouldIncludeSameAs) {
    jsonLd.sameAs = [
      `https://www.instagram.com/${instagramHandle}/`,
      `https://www.youtube.com/@${youtubeHandle}`,
      `https://www.facebook.com/${facebookHandle}/`,
    ];
  }

  if (aggregateRating) {
    jsonLd.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: aggregateRating.ratingValue,
      reviewCount: aggregateRating.reviewCount,
    };
  }

  return jsonLd;
};
