export const INTERACTIVE_JOURNEY_ANIMATION_DELAY = 3000;

export const INTERACTIVE_JOURNEY_ANIMATION_DURATION = 300;

/**
 * Calculation:
 *
 * Coin icon      = 58px,
 * Margins        = 20px,
 * Positioning    = 20px,
 * Total          = 98px.
 */
export const INTERACTIVE_JOURNEY_WIDGET_MAX_WIDTH = 'calc(100vw - 98px)';

export const WHATSAPP_OTP_LENGTH = 6;

export const WHATSAPP_SECONDS_BETWEEN_RESENDS = 60;

// TODO: `MAX_ACCOUNT_AGE_IN_DAYS` will be made dynamic in:
//   * https://zalora.atlassian.net/browse/HN-1759
export const MAX_ACCOUNT_AGE_IN_DAYS = 30;
