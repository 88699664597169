import React, { forwardRef } from 'react';
import SCREEN_SIZES from 'constants/screen-size';
import withIntersectionObserver from 'hocs/withIntersectionObserver';

type Props = {
  src: string;
  alt?: string;
  className?: string;
  onClick: () => void;
  isMobile: boolean;
  ratio?: number;
};

const SponsoredBannerImage = forwardRef<HTMLDivElement, Props>(
  ({ src, alt, className, isMobile, ratio, onClick }: Props, ref) => {
    return (
      <picture
        onClick={onClick}
        className={className}
        ref={ref}
      >
        <source
          media={
            isMobile
              ? `(max-width: ${SCREEN_SIZES.DESKTOP.minPixels}px)`
              : `(min-width: ${SCREEN_SIZES.DESKTOP.minPixels}px)`
          }
          srcSet={src}
        />
        <img
          // React hasn't added the fetchPriority attribute to the img tag yet
          // @ts-ignore
          // eslint-disable-next-line react/no-unknown-property
          fetchPriority="high"
          style={{ aspectRatio: ratio ? ratio : 'auto' }}
          alt={alt}
          className="w-full rounded-lg bg-grey-20 object-contain"
        />
      </picture>
    );
  },
);

export default withIntersectionObserver(SponsoredBannerImage);
