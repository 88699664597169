import { SkeletonItem } from 'components/skeleton';

export const SkeletonCatalogBanner = () => {
  return (
    <div className="flex items-center justify-between">
      <SkeletonItem
        width="w-full"
        height="h-64"
      />
    </div>
  );
};
