import { ZDTAds } from '@zalora/doraemon-ts';
import { CatalogBannerType } from './catalog/catalog-ads';

/**
 * @description: This function returns the size of mobile sponsored banner and desktop sponsored banner
 * @param {Array} sponsoredBannerSizes
 * @returns {Array} [mobileSponsoredBannerSize, desktopSponsoredBannerSize]
 */
export const getMobileAndDesktopSponsoredBannerSizes = (
  sponsoredBannerSizes: ZDTAds.BannerSize[],
) => {
  const mobileSponsoredBannerSize =
    sponsoredBannerSizes.find(
      (banner) => banner.SlotID === CatalogBannerType.CategorySearchMobile,
    ) || null;
  const desktopSponsoredBannerSize =
    sponsoredBannerSizes.find((banner) => banner.SlotID === CatalogBannerType.CategorySearch) ||
    null;

  return [mobileSponsoredBannerSize, desktopSponsoredBannerSize];
};
