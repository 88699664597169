export interface InteractiveJourneyHeader {
  id: string;
  title: string;
  fallbackTitle: string;
}

export interface InteractiveJourneyContentfulResponse {
  interactiveJourneyCollection: {
    items: InteractiveJourney[];
  };
}

interface InteractiveJourney {
  headersCollection: HeadersCollection;
  stepsCollection: StepsCollection;
  widgetsCollection: WidgetsCollection;
}

export interface HeadersCollection {
  items: InteractiveJourneyHeader[];
}

// Note that there are variable names that are not included in the types below.
// e.g. "default-nc" or "campaign-sale50"
export const enum InteractiveJourneyHeaderID {
  DEFAULT = 'default',
  SIGNED_UP = 'signed-up',
  COMPLETED = 'completed',
  CAMPAIGN = 'campaign',
}

export interface InteractiveJourneyStep {
  identifier: string;
  description: string;
  voucherCode: string;
  icon: {
    height: number;
    width: number;
    url: string;
  };
}

export const enum InteractiveJourneyStepID {
  INSTALL = 'install',
  SIGN_UP = 'sign-up',
  ENABLE_WHATSAPP = 'enable-whatsapp',
}

export interface StepsCollection {
  items: InteractiveJourneyStep[];
}

export interface InteractiveJourneyStepAugmented extends InteractiveJourneyStep {
  shouldShowVoucher: boolean;
  url: string;
  onClick?: () => void;
}

export interface InteractiveJourneyWidget {
  id: string;
  descriptionDefault: string;
  descriptionExpanded: string;
  confettiText: string;
  confettiPromotionText: string;
}

// Note that there are variable names that are not included in the types below.
// e.g. "campaign-sale50" or "campaign-sale50-signed-up"
export const enum InteractiveJourneyWidgetID {
  DEFAULT = 'default',
  SIGNED_UP = 'signed-up',
  CAMPAIGN = 'campaign',
  ENABLED_WHATSAPP = 'enabled-whatsapp',
}

export interface WidgetsCollection {
  items: InteractiveJourneyWidget[];
}

export const enum InteractiveJourneyAnimationStatus {
  IDLE = 'idle',
  GROWING = 'growing',
  SHRINKING = 'shrinking',
  FINISHED = 'finished',
  ENDED = 'ended',
}

export const enum LocalStorageKeys {
  ASSIGN_IJ = 'assign_IJ',
}

export const enum StopCondition {
  ZUID_EXIST_30_DAYS = 'zuid_exist_30_days',
  FIRST_ORDER_PLACED = 'first_order_placed',
}

export const enum UserState {
  ANONYMOUS = 'anonymous',
  LOGGED_IN = 'loggedin',
  SIGNED_UP = 'signedup',
}
