import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { wrapHtmlInTemplate } from 'utils/cms';

interface Props {
  content: string;
  style: string;
  renderSpecificContent: (string: string) => void;
  onLoaded?: () => void;
}

export const useHtmlIFrame = ({ content, style, renderSpecificContent, onLoaded }: Props) => {
  const [iFrameHeight, setIFrameHeight] = useState<number>(0);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(iFrameHeight === 0);

  const adjustHeight = useCallback((iframeHtmlRect: DOMRect, iframeBodyRect: DOMRect) => {
    const height = Math.max(iframeHtmlRect?.height || 0, iframeBodyRect?.height || 0);

    setIFrameHeight(Boolean(height) ? Math.ceil(height) : 0);
  }, []);

  const heightObserverRef = useRef<ResizeObserver>();

  const htmlContent = useMemo(
    () => wrapHtmlInTemplate(content, style, renderSpecificContent),
    [content, renderSpecificContent, style],
  );

  useEffect(() => {
    let timeout: number = -1;

    if (!content) {
      return;
    }

    const onContentResize = (entries: ResizeObserverEntry[]) => {
      window.requestAnimationFrame(() => {
        adjustHeight(entries?.[0]?.contentRect, entries?.[1]?.contentRect);
      });
    };

    heightObserverRef.current = new ResizeObserver(onContentResize);

    timeout = window.setTimeout(() => {
      setShowSkeleton(false);
    }, 2000);

    return () => {
      if (timeout !== -1) {
        window.clearTimeout(timeout);
      }
    };
  }, [adjustHeight, content]);

  const onIframeLoad = useCallback(
    (target: HTMLIFrameElement) => {
      const elIFrame = target as HTMLIFrameElement;

      if (!elIFrame.contentWindow) {
        setShowSkeleton(false);
        setIFrameHeight(0);

        return;
      }

      const iframeHtml = elIFrame.contentWindow.document.documentElement;
      const iframeBody = elIFrame.contentWindow.document.body;

      heightObserverRef.current?.observe(iframeHtml);
      heightObserverRef.current?.observe(iframeBody);

      adjustHeight(iframeHtml.getBoundingClientRect(), iframeBody.getBoundingClientRect());
      setShowSkeleton(false);

      if (onLoaded) {
        onLoaded();
      }
    },
    [adjustHeight, onLoaded],
  );

  const unLoadIframe = () => {
    if (heightObserverRef.current) {
      heightObserverRef.current.disconnect();
    }
  };

  return { htmlContent, onIframeLoad, unLoadIframe, iFrameHeight, showSkeleton };
};
