import { useStaticCatalogContext } from 'context/CatalogContext';
import { useRouter } from 'next/router';
import React from 'react';
import { StructuredData } from 'components/SEO';
import { getDomainByLocale } from 'utils/domains';

export const BreadcrumbListStructuredData = () => {
  const { breadcrumbs } = useStaticCatalogContext();
  const { locale = '' } = useRouter();
  const domain = getDomainByLocale(locale);

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs?.map((breadcrumb, idx) => ({
      '@type': 'ListItem',
      position: idx + 1,
      item: {
        // encodeURI is used to escape special characters in the URL to avoid XSS
        '@id': `${domain}${encodeURI(breadcrumb.url)}`,
        name: encodeURI(breadcrumb.label),
      },
    })),
  };

  return (
    <StructuredData
      data-rh="true"
      jsonLd={jsonLd}
      id="BreadcrumbList"
    />
  );
};
