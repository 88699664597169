import { useDynamicCatalogContext } from 'context/CatalogContext';
import { useRouter } from 'next/router';
import { FC, memo } from 'react';
import { StructuredData } from 'components/SEO/StructuredData';
import { getProductListJsonLd } from './utils';

interface Props {
  metaTitle: string;
  metaDescription: string;
  pageURL: string;
  mainImageThumbnail: string;
}

const ProductListStructuredData: FC<Props> = ({
  metaTitle,
  metaDescription,
  pageURL,
  mainImageThumbnail,
}) => {
  const { locale } = useRouter();
  const {
    data: { products },
  } = useDynamicCatalogContext();
  const productList = products?.Products || [];

  if (productList.length === 0) {
    return null;
  }

  return (
    <StructuredData
      jsonLd={getProductListJsonLd({
        metaTitle,
        metaDescription,
        pageURL,
        mainImageThumbnail,
        products: productList,
        numberOfItems: products?.NumProductFound || 0,
        locale,
      })}
      id="ProductListStructuredData"
    />
  );
};

export default memo(ProductListStructuredData);
