export const enum TestIdsCatalog {
  AD = 'adLabel',
  ALL_FILTERS_BUTTON = 'filtersButton',
  APPLIED_ITEM = 'appliedItem',
  APPLY_FILTER_BUTTON = 'applyFilterButton',
  BRAND_FILTER_BUTTON = 'brandFilterButton',
  CATALOG_INFO = 'catalogInfo',
  CATEGORY_FILTER_CARD = 'categoryFilterCard',
  CATEGORIES_ALL_OPTION = 'categoriesAllOption',
  COLOUR_FILTER_BUTTON = 'colorFilterButton',
  FILTER_BUTTON = 'filterButton',
  FILTER_CARD = 'filterCard',
  FILTER_CARD_CONTENT = 'filterCardContent',
  FILTER_OPTION = 'filterOption',
  NO_RESULTS = 'noResults',
  ORIGINAL_PRICE = 'originalPrice',
  PAGINATION_NEXT_BUTTON = 'nextLink',
  PAGINATION_NUMBER_BUTTON = 'pageLink',
  PAGINATION_PREVIOUS_BUTTON = 'prevLink',
  PRODUCT_IMAGE = 'productImages',
  PRODUCT_LINK = 'productLink',
  QUICK_SIZE_FILTER_BUTTON = 'quickSizeFilterButton',
  RATING = 'rating',
  REMOVE_BUTTON = 'removeButton',
  SEARCH_TERM_HEADER = 'searchTerm',
  SEGMENT_FILTER = 'segmentFilter',
  SEGMENT_TABS_TAB = 'segmentTabsTab',
  SORT_FILTER_BUTTON = 'sortButton',
  SORT_OPTION_INPUT = 'sortOption',
  SPECIAL_PRICE = 'specialPrice',
  VIEW_ALL_FILTERS_BUTTON = 'viewAllFilterOptions',
  WISHLIST_BUTTON = 'wishlistBtn',
  ZALORA_VIP_FILTER_BUTTON = 'vipFilterButton',
  INTERNAL_LINK_ITEM = 'internalLink',
}
