import { getEnvConfiguration } from './env-configuration';

export const JQUERY_SCRIPT_FILE = '/js/jquery-1.7.min.js';

export const createScriptTag = (script: string, CC?: string) => {
  if (!CC) {
    return '';
  }

  const isStartWithSlash = script.startsWith('/');
  const slash = isStartWithSlash ? '' : '/';
  const src = `https://static-${CC}.zacdn.com${slash}${script}`;

  return `<script src="${src}" type="text/javascript"></script>`;
};

export const triggerIFrameLoadedScriptTag = () => {
  return `<script type="text/javascript">
              
              let checkingLoaded = setInterval(function() {
                if (document.body && document.body.scrollHeight > 0 && window.parent.isHandleIFrameLoadAttached) {
                  window.parent.postMessage('iFrameLoaded', '*');
                  clearInterval(checkingLoaded);
                }
              }, 100);

              setTimeout(function() {
                clearInterval(checkingLoaded);
              }, 10000);

              window.onload = function () {
                  window.parent.postMessage('iFrameLoaded', '*');
              }
          </script>`;
};

export const wrapHtmlInTemplate = (
  html: Nullishable<string>,
  style: string,
  renderSpecificContent: (string: string) => void,
) => {
  if (!html) {
    return null;
  }

  const isHtmlIncludeHtmlTag = html.includes('<html');
  const isIncludedJquery = html.includes('$(');
  const countryCode = getEnvConfiguration('CC');
  // There some link in CMS which set the target="_self"
  // which make the issue on navigation inside iframe
  let htmlContent = html.replace(/target="_self"/g, 'target="_top"');

  // adding loading lazy to all img tag except the first image
  // this helps to avoid to fetch all images at the same time
  // add fetchpriority="high" for only the first image to make sure it is loaded first
  // because the first image is usually the largest image
  htmlContent = htmlContent.replace(/<img/g, '<img loading="lazy" ');
  htmlContent = htmlContent.replace(/<img loading="lazy"/, '<img loading="eager" ');
  htmlContent = htmlContent.replace(/<img/, '<img fetchpriority="high" ');

  if (isHtmlIncludeHtmlTag) {
    /**
     * if the CMS already include the html tag, we don't need to wrap it into html
     * but, we still need to add legacy style and wrap the body into <div class="fullWidthBanner">
     * to make sure it is the same with Alita
     */

    let result = htmlContent;
    const regex = /<body>(.*)<\/body>/gms;
    const matches = regex.exec(htmlContent);
    const innerBody = matches?.[1];

    if (innerBody) {
      const newBody = `
      <base target="_top">
      ${triggerIFrameLoadedScriptTag()}
      <body>
      ${isIncludedJquery ? createScriptTag(JQUERY_SCRIPT_FILE, countryCode) : ''}
        ${style || ''}
        ${renderSpecificContent(htmlContent)}
      </body>`;

      result = result.replace(matches[0], newBody);
    }

    return result;
  }

  /**
   * if the CMS does not includes html, then we have to wrap it by html tag
   * append some legacy style and jquery
   */
  return `
  <!DOCTYPE html>
  <html lang="en">
      <head>
          <meta charset="utf-8">
          <base target="_top">
          ${isIncludedJquery ? createScriptTag(JQUERY_SCRIPT_FILE, countryCode) : ''}
          ${style || ''}
          ${triggerIFrameLoadedScriptTag()}
      </head>
      <body>
       ${renderSpecificContent(htmlContent)}
      </body>
  </html>
`;
};

export const getFirstRegexGroupMatch = (inputString: string, regex: RegExp) => {
  if (!inputString) {
    return '';
  }

  const matches = regex.exec(inputString);

  if (!matches || !matches.length || !matches[1]) {
    return '';
  }

  return matches?.[1];
};
