import { useDynamicCatalogContext } from 'context/CatalogContext';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';
import { OrganizationStructuredData } from 'components/OrganizationStructuredData';
import { useUiStore } from 'stores/ui';
import * as uiSelectors from 'stores/ui/selectors';
import { getReviewInfo } from 'utils/catalog/catalog-seo';
import { getCountryCode } from 'utils/countries';
import { getDomainByLocale } from 'utils/domains';

interface Props {
  name: string;
  url: string;
}

export const CatalogOrganizationStructuredData: FC<Props> = ({ name, url }) => {
  const { locale } = useRouter();
  const { data } = useDynamicCatalogContext();

  const countryCode = getCountryCode() || '';
  const activeSegment = useUiStore(uiSelectors.activeSegment);

  const reviewInfo = useMemo(() => {
    /**
     * Run experiment this feature on ID with segment: kids & lifestyle only
     * This logic will be removed when the experiment is finished
     */
    return countryCode === 'id' && ['kids', 'lifestyle'].includes(activeSegment)
      ? getReviewInfo(data.sponsoredProducts?.Products, data.products?.Products)
      : null;
  }, [countryCode, activeSegment, data.sponsoredProducts?.Products, data.products?.Products]);

  if (!reviewInfo || reviewInfo.avgRating === 0) {
    return null;
  }

  const domain = getDomainByLocale(locale || '');

  return (
    <OrganizationStructuredData
      locale={locale}
      pageData={{
        name,
        url: `${domain}${url}`,
        aggregateRating: {
          ratingValue: reviewInfo.avgRating,
          reviewCount: reviewInfo.totalReviews,
        },
      }}
    />
  );
};
