import { ZDTCustomer } from '@zalora/doraemon-ts';
import { NextRouter } from 'next/router';
import { CUSTOMER_TYPES } from 'constants/customer';
import { PAGES_TYPES } from 'constants/page-types';
import { getPageType } from 'utils/router';
import { captureError } from './raven';

export const getSystemCustomerType = (
  customer: Nullishable<ZDTCustomer.Customer>,
  router: NextRouter,
) => {
  const isExistingCustomer = !!customer?.Id;
  const orderCount = customer?.OrderCount || 0;
  const isInSuccessPage = getPageType(router.pathname).includes(PAGES_TYPES.success);
  const isSubscribed = !!customer?.Zuid; // TODO: Add check if user has newsletterSignup

  switch (true) {
    case isExistingCustomer && orderCount < 1:

    case isExistingCustomer && orderCount === 1 && isInSuccessPage: {
      return CUSTOMER_TYPES.CUSTOMER_TYPE_NEVER_PURCHASED;
    }

    case isExistingCustomer && orderCount === 1 && !isInSuccessPage: {
      return CUSTOMER_TYPES.CUSTOMER_TYPE_FIRST_PURCHASE;
    }

    case isExistingCustomer && orderCount > 1: {
      return CUSTOMER_TYPES.CUSTOMER_TYPE_PURCHASED;
    }

    case isSubscribed: {
      return CUSTOMER_TYPES.CUSTOMER_TYPE_NL_SUBSCRIBED;
    }

    default: {
      return CUSTOMER_TYPES.CUSTOMER_TYPE_VISITOR;
    }
  }
};

export const getBirthYear = (birthday: unknown) => {
  try {
    const birthyear = new Date(`${birthday}T00:00:00`).getFullYear();

    return isNaN(birthyear) ? undefined : birthyear;
  } catch (error) {
    return undefined;
  }
};

export const getAccountAgeInDays = async (user: Nullishable<ZDTCustomer.Customer>) => {
  if (!user || !user.CreatedAt) {
    return NaN;
  }

  try {
    const { getDifferenceInDays } = await import('utils/date');

    return getDifferenceInDays(new Date(), user.CreatedAt, 'yyyy-MM-dd HH:mm:ss');
  } catch (error) {
    captureError('Error calculating account age in days', {
      error,
      tag: 'get-request',
    });

    return NaN;
  }
};
