import { useRouter } from 'next/router';
import useSWRImmutable from 'swr/immutable';
import { QueryParams } from 'api/APIClient';
import { getUserAgent, isSearchCrawler } from 'utils/bots';
import { getCatalogAds } from 'utils/catalog/catalog-ads';
import { PageInfo } from 'utils/catalog/catalog-page-type';

const useAds = (pageInfo: PageInfo, shouldCallApi = true) => {
  const { query = '' } = useRouter();
  const userAgent = getUserAgent();
  const isBot = isSearchCrawler(userAgent);

  // https://zalora.atlassian.net/wiki/spaces/FE/pages/3729719348/Dynamic+rendering+for+Catalog+pages
  // Dynamic rendering
  // Do not render banner if request from bots
  const swrCacheKey = isBot || !shouldCallApi ? null : `${JSON.stringify(pageInfo)}/query/locale`;

  return useSWRImmutable(swrCacheKey, () => getCatalogAds(pageInfo, query as QueryParams));
};

export default useAds;
