import useUser from 'hooks/api/useUser';
import { getCountryCode } from 'utils/countries';
import * as SegmentTracking from 'utils/segment-analytic';

const EVENT_NAMES = {
  BREADCRUMBS_CLICKED: 'Breadcrumbs Clicked',
} as const;

export const enum SOURCE_PAGE_TYPE {
  PRODUCT_DETAILS = 'Product Details',
  CATALOG = 'Catalog',
  EMPTY = '',
}
interface Props {
  title: string;
  sourcePageType: SOURCE_PAGE_TYPE;
  sourceSegment: string;
  deeplink: string;
  userData?: ReturnType<typeof useUser>;
  language?: string;
  activeSegment?: string;
  emailSubscribed: boolean;
}

export const trackingOnClickBreadcrumb = ({
  title,
  sourcePageType,
  sourceSegment,
  deeplink,
  userData,
  language,
  activeSegment,
  emailSubscribed,
}: Props) => {
  const countryCode = getCountryCode();
  const { data: user } = userData || {};
  const payload = {
    title,
    source_page_type: sourcePageType,
    source_segment: sourceSegment,
    shop_language: language,
    shop_gender: activeSegment,
    shop_country: countryCode,
    login_status: !!user,
    id: user?.Id || '',
    userId: user?.Zuid || '',
    birthday: user?.Birthday || '',
    email_subscribe: emailSubscribed || false,
    email_gender: user?.Gender || '',
    znow_subscribe: !!user?.IsVipMember,
    deeplink,
  };

  SegmentTracking.track(EVENT_NAMES.BREADCRUMBS_CLICKED, payload);
};
