import Script from 'next/script';
import React from 'react';
import { getEnvConfiguration } from 'utils/env-configuration';

export const CitrusTracking = () => {
  const baseUrl = getEnvConfiguration('CITRUS_BASE_URL') || '';
  const source = getEnvConfiguration('CITRUS_SDK_URL') || '';

  return (
    <Script
      type="text/javascript"
      id="CitrusTracking"
      strategy="afterInteractive"
    >
      {`!function(t,e,r){let i=[],n=[];t.citrusAd={},t.citrusAd.reportClick=function(t){i.push(t)},t.citrusAd.reportImpression=function(t){n.push(t)},document.addEventListener("readystatechange",function(){if("complete"!==document.readyState)return;let s=document.createElement("script");s.onload=function(){if(void 0===t.CitrusAd){Raven.captureMessage("Cannot init Citrus tracking");return}t.citrusAd=CitrusAd.init(e,{disableTracking:!1}),i.map(t.citrusAd.reportClick,{baseUrl:e}),n.map(t.citrusAd.reportImpression,{baseUrl:e})},s.type="text/javascript",s.src=r,document.getElementsByTagName("head")[0].appendChild(s)})}(window,"${baseUrl}","${source}");`}
    </Script>
  );
};
