import useSWRImmutable from 'swr/immutable';
import doraemon from 'api/doraemon';
import FeatureFlags from 'constants/feature-flags';
import { MAX_ACCOUNT_AGE_IN_DAYS } from 'constants/interactive-journey';
import { StopCondition } from 'types/interactive-journey';
import { getAnonymousId } from 'utils/anonymous-id';
import { getAccountAgeInDays } from 'utils/customer';
import { useEnabledBobFeatureFlag } from './api/useEnabledBobFeatureFlag';
import useUser from './api/useUser';
import { useDeviceType } from './useDeviceType';

/**
 * See https://miro.com/app/board/uXjVKz2jOJg=/ for scenarios.
 */
const useInteractiveJourneyEnabled = (): boolean => {
  const { isMobile } = useDeviceType();
  const { data: user, isLoading: isLoadingUser } = useUser();
  const isFlagEnabled = useEnabledBobFeatureFlag(FeatureFlags.Bob.LOTUS_ENABLE_INTERACTIVE_JOURNEY);

  const { data: orderCount, isLoading: isLoadingOrderCount } = useSWRImmutable(
    isFlagEnabled && isMobile && user ? user.Id : null,
    async () => {
      /**
       * This is a workaround to get the order count for the user. If a user
       * creates a new order through Alita, the checkout page doesn't update the
       * order count in Alita's session. This results in Doraemon returning an
       * incorrect order amount for the "GET /customers" endpoint. The "GET
       * /orders/items" endpoint talks to Bob directly.
       */
      const { data } = await doraemon.request('GET /orders/items', {
        request: { credentials: 'include' },
        params: { query: { limit: 1 } },
        transform: (orderList) => {
          return orderList.Items ? orderList.Items.length : undefined;
        },
      });

      return data;
    },
  );

  const { data: result } = useSWRImmutable(
    isFlagEnabled && isMobile && !isLoadingUser && !isLoadingOrderCount
      ? ['interactive-journey/is-enabled', getAnonymousId()]
      : null,

    async () => {
      let isEnabled = true;
      let stopCondition: StopCondition | undefined = undefined;

      // Ineligible due to order count
      if (typeof orderCount === 'number' && orderCount > 0) {
        isEnabled = false;
        stopCondition = StopCondition.FIRST_ORDER_PLACED;
      }

      // Ineligible due to account age
      else if ((await getAccountAgeInDays(user)) > MAX_ACCOUNT_AGE_IN_DAYS) {
        isEnabled = false;
        stopCondition = StopCondition.ZUID_EXIST_30_DAYS;
      }

      return { isEnabled, stopCondition };
    },

    {
      onSuccess: ({ isEnabled, stopCondition }) => {
        import('utils/tracking/interactive-journey')
          .then(({ trackAfterIJEligibilityCheck }) => {
            trackAfterIJEligibilityCheck({
              isIJEnabled: isEnabled,
              stopCondition,
              user,
            });
          })
          .catch(() => {
            /* Noop */
          });
      },
    },
  );

  return result ? result.isEnabled : false;
};

export default useInteractiveJourneyEnabled;
