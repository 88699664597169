import { ZDTAds } from '@zalora/doraemon-ts';
import React, { FC, memo } from 'react';
import FullWidthBanner from './FullWidthBanner';
import MobileCampaignBanner from './MobileCampaignBanner';

interface Props {
  catalogBanner: ZDTAds.Banner | null;
  isFullScreenBanner: boolean;
}

const CampaignBanner: FC<Props> = ({ catalogBanner, isFullScreenBanner }) => {
  if (!catalogBanner) {
    return null;
  }

  return (
    <>
      {catalogBanner.BannerFullWidth ? (
        <FullWidthBanner
          content={catalogBanner.BannerFullWidth}
          isFullScreenBanner={isFullScreenBanner}
        />
      ) : null}
      {catalogBanner.ImageURL ? <MobileCampaignBanner imageURL={catalogBanner.ImageURL} /> : null}
    </>
  );
};

export default memo(CampaignBanner);
