import { ZDTAds } from '@zalora/doraemon-ts';
import React from 'react';
import { trackingClick, trackingImpression } from 'utils/tracking/zap';
import SponsoredBannerImage from './SponsoredBannerImage';

type Props = {
  banner: Nullishable<ZDTAds.Banner>;
  isMobile?: boolean;
  className?: string;
  ratio?: number;
};

export const SponsoredBannerComponent = ({ banner, isMobile = false, className, ratio }: Props) => {
  if (!banner) {
    return null;
  }

  if (banner.LinkURL) {
    return (
      <a
        href={banner.LinkURL}
        className={className}
      >
        <SponsoredBannerImage
          ratio={ratio}
          isMobile={isMobile}
          src={banner.ImageURL}
          alt="Sponsored Banner"
          trackingData={banner.AdID}
          onImpression={() => trackingImpression(banner.AdID)}
          onClick={() => trackingClick(banner.AdID)}
        />
      </a>
    );
  }

  return (
    <SponsoredBannerImage
      ratio={ratio}
      isMobile={isMobile}
      src={banner.ImageURL}
      alt="Sponsored Banner"
      trackingData={banner.AdID}
      onImpression={() => trackingImpression(banner.AdID)}
      onClick={() => trackingClick(banner.AdID)}
      className={className}
    />
  );
};
