import { OptimizelyContext } from '@optimizely/react-sdk';
import { ZDTAds, ZDTSEO } from '@zalora/doraemon-ts';
import {
  DynamicCatalogContextProvider,
  StaticCatalogContextProvider,
} from 'context/CatalogContext';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { SSRConfig as nextI18NextSSRConfig } from 'next-i18next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactElement, useContext, useEffect } from 'react';
import { unstable_serialize as unstableSerialize } from 'swr';
import { QueryParams } from 'api/APIClient';
import { getSponsoredBannerSize } from 'api/ads';
import { getCMSBanner } from 'api/banner';
import { ProductListingApi, getProductList } from 'api/catalog';
import { fetchCatalogSeoInfo } from 'api/seo';
import Layout from 'components/Layout/Layout';
import Catalog from 'components/catalog/Catalog';
import CatalogBanner from 'components/catalog/CatalogBanner';
import { CatalogBreadcrumbs } from 'components/catalog/CatalogBreadcrumbs';
import CatalogHead from 'components/catalog/CatalogHead/CatalogHead';
import { CatalogInfo } from 'components/catalog/CatalogInfo';
import ReviewInfo from 'components/catalog/ReviewInfo/ReviewInfo';
import InteractiveJourneyWidgetContainer from 'components/interactive-journey/InteractiveJourneyWidget/InteractiveJourneyWidgetContainer';
import { FF_CONFIG_BY_ROUTE } from 'constants/bob-feature-flags';
import FeatureFlags from 'constants/feature-flags';
import { NOT_APPLICABLE } from 'constants/optimizely';
import { Routes } from 'constants/routes';
import { TrackingDataProps } from 'constants/tracking';
import useAds from 'hooks/catalog/useAds';
import { useEnrichAttributes } from 'hooks/catalog/useEnrichAttributes';
import { callDecisionMethod } from 'hooks/useOptimizelyDecision';
import { useUiStore } from 'stores/ui';
import * as uiSelectors from 'stores/ui/selectors';
import { getMobileAndDesktopSponsoredBannerSizes } from 'utils/banners';
import { constructCacheKey, fetchBobFeatureFlags } from 'utils/bob-feature-flags';
import { getUserAgent, isSearchCrawler } from 'utils/bots';
import { isValidSeoInfo } from 'utils/catalog-product';
import { buildCatalogPath } from 'utils/catalog/build-catalog-path';
import { PageInfo, isSearchPageInfo } from 'utils/catalog/catalog-page-type';
import { getRobots } from 'utils/catalog/catalog-seo';
import {
  getSearchVolumeKeywords,
  shouldShowCategoryLinksBySearchVolume,
} from 'utils/catalog/category-search-volume/search-volume-keywords';
import { parsePageDataFromPath } from 'utils/catalog/parse-page-from-url';
import { getCatalogParams } from 'utils/catalog/query';
import { Robots } from 'utils/catalog/types';
import { getStaticAsset } from 'utils/domains';
import { getCategoryFilterParams } from 'utils/filters/categoryFilter';
import { serverSideTranslations } from 'utils/i18n';
import logger from 'utils/logger';
import fetchLayoutData, { fetchActiveSegments } from 'utils/server-layout-data';
import { preloadStoreState } from 'utils/stores/preloadStoreState';
import { initialiseTrackingData } from 'utils/tracking';
import { NextPageWithLayout } from '../_app.page';

const ModalDialogRenderer = dynamic(() => import('components/ModalDialog/ModalDialogRenderer'), {
  ssr: false,
});

const SocialProofingManager = dynamic(
  () => import('components/SocialProofing/SocialProofingManager'),
  { ssr: false },
);

interface Props extends nextI18NextSSRConfig {
  pageInfo: PageInfo;
  seoInfo: ZDTSEO.CatalogInfo;
  fallback?: { [key: string]: (FeatureFlags.BobFlag | FeatureFlags.OptimizelyFlag)[] };
  isSearchCrawler: boolean;
  sponsoredBannerSizes: ZDTAds.BannerSize[];
  cmsBanner?: ZDTAds.Banner;
  robots: Robots;
}

const CatalogPage: NextPageWithLayout<Props> = ({
  pageInfo,
  seoInfo,
  isSearchCrawler,
  sponsoredBannerSizes,
  cmsBanner,
  robots,
}) => {
  const { variationKey, isEnrichAttributeEnabled } = useEnrichAttributes();
  const router = useRouter();
  const activeSegment = useUiStore(uiSelectors.activeSegment);
  const [mobileSponsoredBannerSize, desktopSponsoredBannerSize] =
    getMobileAndDesktopSponsoredBannerSizes(sponsoredBannerSizes);
  const { data: catalogAds } = useAds(pageInfo, !!sponsoredBannerSizes?.length);
  const { optimizely } = useContext(OptimizelyContext);

  useEffect(() => {
    if (!optimizely || variationKey === null) {
      return;
    }

    if (!isEnrichAttributeEnabled || variationKey === NOT_APPLICABLE) {
      callDecisionMethod(optimizely, FeatureFlags.Optimizely.ENABLE_DJ_CATALOG);
    } else {
      callDecisionMethod(optimizely, FeatureFlags.Optimizely.LOTUS_ENABLE_ENRICHED_ATTRIBUTES);
    }
  }, [optimizely, variationKey, isEnrichAttributeEnabled]);

  useEffect(() => {
    const trackingData: TrackingDataProps = {
      activeSegment,
      seoInfo,
    };

    initialiseTrackingData(trackingData);
  }, [router, pageInfo, activeSegment, seoInfo]);

  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          // this is a hint to preload the skeleton product image for catalog page
          // It helps to fetch the image ASAP, then our LCP will be better
          // the size of this image is 1kb so it doesnt affect other pages too much
          href={getStaticAsset('/static-assets/images/product_skeleton.webp')}
        />
      </Head>
      <Head>
        <link
          rel="preload"
          as="image"
          // this is a hint to preload the sponsored banner image for catalog page
          // It helps to fetch the image ASAP, then our LCP will be better
          // it's acceptable to preload this image when we dont need to use it
          // because this helps us to avoid increase unnecessary complexity
          href={getStaticAsset('/static-assets/images/catalog/banner_skeleton.webp')}
        />
      </Head>
      <StaticCatalogContextProvider
        pageInfo={pageInfo}
        seoInfo={seoInfo}
        // this trick is used to pass ts error
        catalogAds={catalogAds || undefined}
        cmsBanner={cmsBanner}
        isSearchCrawler={isSearchCrawler}
        isEnrichAttributeEnabled={isEnrichAttributeEnabled}
      >
        <DynamicCatalogContextProvider>
          <SocialProofingManager />
          <CatalogHead />

          <CatalogBanner
            mobileSponsoredBannerSize={mobileSponsoredBannerSize}
            desktopSponsoredBannerSize={desktopSponsoredBannerSize}
          />

          <InteractiveJourneyWidgetContainer />

          <div className="mx-auto min-h-screen w-full max-w-screen-xl desktop:p-3 desktop:pt-6">
            <CatalogBreadcrumbs />

            <Catalog />

            {/* 320 is width of filter pannel */}
            <div className="my-4 px-4 desktop:ml-[320px] desktop:px-0 desktop:pl-5">
              <ReviewInfo />
            </div>

            <CatalogInfo
              seoText={seoInfo.SeoText}
              robots={robots}
            />
          </div>

          <ModalDialogRenderer />
        </DynamicCatalogContextProvider>
      </StaticCatalogContextProvider>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const { locale = '', query, req } = context;
  const requestPath = req.url as string;

  const segments = await fetchActiveSegments(locale);
  const pageInfo = parsePageDataFromPath(segments, query.path as string[], requestPath);

  if (pageInfo === null) {
    return { notFound: true };
  }

  const isSearchPage = isSearchPageInfo(pageInfo);
  const activeSegment = (pageInfo.segment as string) || '';

  const [
    layoutData,
    layoutTranslation,
    enabledFeatureFlags,
    seoInfo,
    sponsoredBannerSizes = [],
    cmsBanner,
  ] = await Promise.all([
    fetchLayoutData(locale, true),
    locale
      ? serverSideTranslations(locale, ['common', 'catalog', 'footer', 'interactive-journey'])
      : {},
    fetchBobFeatureFlags({
      routeKey: Routes.CATALOG,
      featureFlagConfig: FF_CONFIG_BY_ROUTE,
      options: { isServerOnly: true },
    }),
    fetchCatalogSeoInfo(pageInfo, locale),
    getSponsoredBannerSize(pageInfo, query as QueryParams),
    getCMSBanner(pageInfo, query as QueryParams, locale),
  ]);

  if (!isValidSeoInfo({ pageInfo, seoInfo })) {
    logger.warn('[CATALOG] - there is a mismatch between page info and seo info', {
      pageInfo,
      seoInfo,
      requestPath,
    });

    return { notFound: true };
  }

  // Filter out `/_next/data` requests
  const isPageRequest = requestPath.startsWith('/c');

  // Verify integrity of slugs by cross-checking with `seoInfo`
  if (isPageRequest && seoInfo) {
    const [, params] = requestPath.split('?');
    const { Brand, CategoryBreadcrumbs, Segment } = seoInfo.PageInfo || {};

    const expectedPath = buildCatalogPath({
      params,
      segment: Segment?.Key,
      brandId: pageInfo.brandId,
      brandSlug: Brand?.UrlKey,
      categoryId: pageInfo.categoryId,
      categorySlug: CategoryBreadcrumbs?.find(({ Id }) => pageInfo.categoryId === Id)?.UrlKey,
      subcategoryId: pageInfo.subCategoryId,
      subcategorySlug: CategoryBreadcrumbs?.find(({ Id }) => pageInfo.subCategoryId === Id)?.UrlKey,
      specialPageType: pageInfo.specialPage,
    });

    if (requestPath !== expectedPath) {
      logger.warn('[CATALOG] - There is a mismatch between current url with expected url', {
        pageInfo,
        seoInfo,
        expectedPath,
        requestPath,
      });

      return {
        redirect: {
          destination: expectedPath,
          permanent: true,
        },
      };
    }
  }

  const swrFallback: { [key: string]: unknown } = {
    [unstableSerialize(constructCacheKey(isSearchPage ? Routes.SEARCH : Routes.CATALOG))]:
      enabledFeatureFlags, // Pass FF in fallback for server side use cases
  };

  // For search crawler purpose
  const userAgent = getUserAgent(req.headers);
  const isSearchCrawlerAgent = isSearchCrawler(userAgent);
  const robots = getRobots(query as QueryParams, pageInfo);

  if (isSearchCrawlerAgent) {
    const params = getCatalogParams(query as QueryParams, pageInfo);
    const categoryParams = getCategoryFilterParams(params, pageInfo);

    const shouldFetchSearchVolumeKeywords = shouldShowCategoryLinksBySearchVolume(robots);

    const [resProducts, resFilters, searchVolumeKeywords] = await Promise.all([
      getProductList(ProductListingApi.list, params),
      getProductList(ProductListingApi.filter, categoryParams),
      shouldFetchSearchVolumeKeywords ? getSearchVolumeKeywords(locale) : null,
    ]).catch(() => []);

    swrFallback[unstableSerialize(['products/list', params])] = resProducts;
    swrFallback[unstableSerialize(['products/filters', categoryParams])] = resFilters;

    if (shouldFetchSearchVolumeKeywords) {
      swrFallback[unstableSerialize(['search-volume-keywords'])] = searchVolumeKeywords;
    }
  }

  return {
    props: {
      ...layoutTranslation,
      isSearchCrawler: isSearchCrawlerAgent,
      pageInfo,
      cmsBanner:
        isSearchCrawlerAgent || sponsoredBannerSizes?.length || !cmsBanner ? null : cmsBanner,
      robots,
      sponsoredBannerSizes: isSearchCrawlerAgent ? [] : sponsoredBannerSizes,
      seoInfo: seoInfo || {},
      ...preloadStoreState({
        ui: {
          activeSegment,
          ...layoutData,
          segments,
        },
        catalog: {},
        optimizely: {},
        cart: {},
      }),
      fallback: swrFallback,
    },
  };
};

CatalogPage.getLayout = (page: ReactElement) => {
  return <Layout shouldShownBackToTop>{page}</Layout>;
};

export default CatalogPage;
