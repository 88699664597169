import clsx from 'clsx';
import React, { FC, memo, useEffect, useRef } from 'react';
import { useHtmlIFrame } from 'hooks/useHtmlIFrame';
import { SkeletonCatalogBanner } from '../CatalogSkeleton/SkeletonCatalogBanner';

const getLegacyStyle = (isFullScreenBanner?: boolean) => {
  return `
  <style>
    html, body {height: unset !important;}
    ${!isFullScreenBanner ? 'body { width: 996px; margin: auto }' : ''}
    html::-webkit-scrollbar { display: none; }
    .fsxs{font-size:1rem}.fss{font-size:1.1rem}.fsm{font-size:1.2rem}.txtRight{text-align:right}.txtLeft{text-align:left}.txtCenter{text-align:center}.bold,strong{font-weight:700}.normal{font-weight:400}dd,ol,ul{list-style:none;margin:0;padding:0}
    a { color: #000; text-decoration: none; }
    .pas,.pts,.pvs{padding-top:5px}.pam,.paml,.ptm,.ptml,.pvm,.pvml{padding-top:12px}.pal,.ptl,.pvl{padding-top:20px}.paxl,.ptxl,.pvxl{padding-top:40px}.pas,.phs,.prs{padding-right:5px}.pam,.paml,.phm,.phml,.prm,.prml{padding-right:12px}.pal,.phl,.prl{padding-right:20px}.paxl,.phxl,.prxl{padding-right:40px}.pas,.pbs,.pvs{padding-bottom:5px}.pam,.paml,.pbm,.pbml,.pvm,.pvml{padding-bottom:12px}.pal,.pbl,.pvl{padding-bottom:20px}.paxl,.pbxl,.pvxl{padding-bottom:40px}.pas,.phs,.pls{padding-left:5px}.pam,.paml,.phm,.phml,.plm,.plml{padding-left:12px}.pal,.phl,.pll{padding-left:20px}.paxl,.phxl,.plxl{padding-left:40px}.man,.mtn,.mvn{margin-top:0}.man,.mbn,.mvn{margin-bottom:0}.mas,.mts,.mvs{margin-top:5px}.mam,.maml,.mtm,.mtml,.mvm,.mvml{margin-top:12px}.mal,.mtl,.mvl{margin-top:20px}.maxl,.mtxl,.mvxl{margin-top:40px}.maxxl,.mtxxl,.mvxxl{margin-top:90px}.mas,.mhs,.mrs{margin-right:5px}.mam,.maml,.mhm,.mhml,.mrm,.mrml{margin-right:12px}.mal,.mhl,.mrl{margin-right:20px}.maxl,.maxxl,.mhxl,.mhxxl,.mrxl,.mrxxl{margin-right:40px}.mas,.mbs,.mvs{margin-bottom:5px}.mam,.maml,.mbm,.mbml,.mvm,.mvml{margin-bottom:12px}.mal,.mbl,.mvl{margin-bottom:20px}.maxxl,.mbxxl,.mvxxl{margin-bottom:90px}.maxl,.mbxl,.mvxl{margin-bottom:40px}.mas,.mhs,.mls{margin-left:5px}.mam,.maml,.mhm,.mhml,.mlm,.mlml{margin-left:12px}.mal,.mhl,.mll{margin-left:20px}.maxl,.mhxl,.mlxl{margin-left:40px}.maxxl,.mhxxl,.mlxxl{margin-left:90px}
    .lfloat,.unit{float:left}.rfloat{float:right}.l-clear{clear:both}.block{display:block!important}.inlineBlock{display:inline-block!important}.inline{display:inline!important}.btn,.inlineList li{display:inline-block}.simpleList{list-style:disc;padding-left:13px}.inlineList li{margin-bottom:4px}.uip{margin:1em 0}.dec{color:#a2a2a2}.circle{border-radius:50%;width:20px;height:20px;border:1px solid #7e7e7e;line-height:22px}.with-gutters{padding:0 16px}.btn{transition:background-color .3s,color .2s;color:#fff;background-color:#000;border:1px solid #000;box-sizing:border-box;text-transform:uppercase;padding:12px;font-size:12px;line-height:10px;text-align:center;white-space:nowrap;vertical-align:middle;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-image:none;letter-spacing:1px}.dropdown-menu-header a:focus,.dropdown-menu-header a:hover{color:#000;text-decoration:underline}.cms-list{margin-left:10px;font-size:11px}.size1of3{width:33.33333%}
  </style>
  `;
};

/**
 * Workaround solution for supporting old CMS
 */

const renderSpecificContent = (htmlContent: string) => {
  return `<div
      class="fullWidthBanner"
      id="js-catalogFullWidthBanner"
    >
      ${htmlContent}
    </div>`;
};

interface Props {
  className?: string;
  content: string;
  isFullScreenBanner?: boolean;
}

const FullWidthBanner: FC<Props> = ({ content, isFullScreenBanner, className }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { htmlContent, iFrameHeight, onIframeLoad, showSkeleton, unLoadIframe } = useHtmlIFrame({
    content,
    style: getLegacyStyle(isFullScreenBanner),
    renderSpecificContent,
  });

  useEffect(() => {
    const handleIFrameLoad = (event: MessageEvent<unknown>) => {
      if (event.data !== 'iFrameLoaded') {
        return;
      }

      if (iframeRef && iframeRef.current) {
        onIframeLoad(iframeRef.current);
      }
    };

    // Use to let the iframe know that the event listener is attached
    window.isHandleIFrameLoadAttached = true;

    window.addEventListener('message', handleIFrameLoad);

    return () => {
      window.removeEventListener('message', handleIFrameLoad);
      window.isHandleIFrameLoadAttached = false;
    };
  }, [htmlContent, onIframeLoad, unLoadIframe]);

  useEffect(() => {
    return () => {
      // Unload iframe when component unmount
      unLoadIframe();
    };
  }, []);

  if (!htmlContent) {
    return null;
  }

  return (
    <div className={clsx('hidden desktop:block', className)}>
      {!iFrameHeight && showSkeleton ? <SkeletonCatalogBanner /> : null}

      <iframe
        id="html-cms"
        ref={iframeRef}
        srcDoc={htmlContent}
        title="html-cms-content"
        width="100%"
        height={iFrameHeight}
        seamless
        suppressHydrationWarning
        sandbox="allow-same-origin allow-scripts allow-modals allow-downloads allow-top-navigation"
        onLoad={(e) => onIframeLoad(e.target as HTMLIFrameElement)}
      ></iframe>
    </div>
  );
};

export default memo(FullWidthBanner);
