import { useStaticCatalogContext } from 'context/CatalogContext';
import { useTranslation } from 'next-i18next';
import { FC, memo } from 'react';
import { buildBrandUrl } from 'utils/catalog/catalog-url';
import RelatedInternalLinks, { RelatedInternalLinkType } from './RelatedInternalLinks';

const RelatedBrands: FC = () => {
  const { t } = useTranslation('catalog');
  const { seoInfo } = useStaticCatalogContext();
  const { Brands } = seoInfo;

  if (!Brands || Brands.length === 0) {
    return null;
  }

  const formattedBrandsList = Brands.reduce((acc, brand) => {
    if (brand.Name) {
      acc.push({
        Id: brand.Id || '',
        Name: brand.Name,
        Url: buildBrandUrl(brand),
      });
    }

    return acc;
  }, [] as RelatedInternalLinkType[]);

  return (
    <RelatedInternalLinks
      title={t('Shop by Brands')}
      links={formattedBrandsList}
    />
  );
};

export default memo(RelatedBrands);
