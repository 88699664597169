import { useStaticCatalogContext } from 'context/CatalogContext';
import { useRouter } from 'next/router';
import { QueryParams } from 'api/APIClient';
import { buildQueryString } from 'api/util';
import { UniversalLinks } from 'components/SEO';
import { getCategoriesParams, getPageInfoParams, getSearchParams } from 'utils/catalog/query';
import { omit } from 'utils/omit';

export const CatalogUniversalLinks = () => {
  const { pageInfo } = useStaticCatalogContext();
  const { query } = useRouter();

  const catalogParams = {
    catalogType: 'Main',
    ...query,
    ...getCategoriesParams(query as QueryParams, pageInfo),
    ...getPageInfoParams(pageInfo),
    ...getSearchParams(query as QueryParams),
  } as Record<string, string>;

  const queryParams = omit(catalogParams, ['query', 'path', 'enableRelevanceClassifier']);
  const searchParams = buildQueryString(queryParams);

  return <UniversalLinks destination={`urlc/product/?${searchParams}`} />;
};
