import { ZDTProduct } from '@zalora/doraemon-ts';
import { StarIcon } from '@zalora/zui-icons';
import { FC, memo } from 'react';
import { TestIdsCatalog } from 'constants/e2eIds/catalog-test-ids';
import { LotusProduct } from 'types/LDT';
import { getReviewStatistics } from 'utils/product';

interface Props {
  product: LotusProduct | ZDTProduct.ProductSearch;
}

const ProductRating: FC<Props> = ({ product }) => {
  const reviewStatistics = getReviewStatistics(product);

  if (!reviewStatistics || !reviewStatistics.AvgRating) {
    return null;
  }

  return (
    <div className="flex items-center gap-x-0.5 rounded bg-grey-10 bg-opacity-90 p-0.5">
      <StarIcon className="h-[9px] w-[9px]" />
      <span
        data-test-id={TestIdsCatalog.RATING}
        className="text-xxs font-medium leading-3 text-grey-60"
      >
        {reviewStatistics.AvgRating.toFixed(1)}
      </span>
    </div>
  );
};

export default memo(ProductRating);
