import { StarRating } from '@zalora/zui';
import { StarFillFullIcon } from '@zalora/zui-icons';
import clsx from 'clsx';
import { useDynamicCatalogContext, useStaticCatalogContext } from 'context/CatalogContext';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';
import { QueryParams } from 'api/APIClient';
import { getReviewInfo, getReviewTitle, getRobots } from 'utils/catalog/catalog-seo';
import { Robots } from 'utils/catalog/types';

const ReviewInfo: FC = () => {
  const router = useRouter();
  const { t } = useTranslation(['common', 'catalog']);
  const { data } = useDynamicCatalogContext();
  const { pageInfo, seoInfo } = useStaticCatalogContext();
  const robots = getRobots(router.query as QueryParams, pageInfo);

  const reviewInfo = useMemo(() => {
    return getReviewInfo(data.sponsoredProducts?.Products, data.products?.Products);
  }, [data.sponsoredProducts, data.products]);
  const shouldShowReviewInfo = reviewInfo.totalReviews > 0 && robots === Robots.INDEX_FOLLOW;
  const title = useMemo(
    () => (shouldShowReviewInfo ? getReviewTitle(seoInfo, t) : ''),
    [seoInfo, t, shouldShowReviewInfo],
  );

  if (!shouldShowReviewInfo || !title) {
    return null;
  }

  return (
    <section
      className={clsx(
        'flex items-center gap-x-4 px-5 py-4',
        'bg-grey-100 text-white',
        'rounded-lg',
      )}
    >
      <div className="flex-1 text-md font-bold">{title}</div>
      <div
        className={clsx(
          'flex flex-col items-start',
          'tablet:flex-row tablet:items-center tablet:gap-x-5',
        )}
      >
        <div className="tracking-wider">
          <span className="text-2xl font-bold">{reviewInfo.avgRating}</span>{' '}
          <StarFillFullIcon className="!size-3 tablet:hidden" /> <span>/5</span>
        </div>
        <StarRating
          score={reviewInfo.avgRating}
          starClassName="!size-3 hidden tablet:block"
        />
        <span className="text-xxs">
          ({reviewInfo.totalReviews} {t('Reviews')})
        </span>
      </div>
    </section>
  );
};

export default ReviewInfo;
