import dynamic from 'next/dynamic';
import React, { FC } from 'react';
import useInteractiveJourneyEnabled from 'hooks/useInteractiveJourneyEnabled';

const InteractiveJourneyWidget = dynamic(
  () => import('components/interactive-journey/InteractiveJourneyWidget'),
  { ssr: false },
);

const InteractiveJourneyWidgetContainer: FC = () => {
  const isInteractiveJourneyEnabled = useInteractiveJourneyEnabled();

  if (!isInteractiveJourneyEnabled) {
    return null;
  }

  return <InteractiveJourneyWidget />;
};

export default InteractiveJourneyWidgetContainer;
