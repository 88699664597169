import { FC } from 'react';
import { StructuredData } from 'components/SEO';
import { getOrganizationStructuredData, OrganizationDataExtended } from 'utils/organization';

interface Props {
  locale?: string;
  pageData?: OrganizationDataExtended;
}

export const OrganizationStructuredData: FC<Props> = ({ locale, pageData }) => {
  const jsonLd = getOrganizationStructuredData(locale, false, pageData);

  if (!jsonLd) {
    return null;
  }

  return (
    <StructuredData
      id="Organization_OnlineStore"
      jsonLd={jsonLd}
    />
  );
};
